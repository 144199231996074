@import url("https://use.typekit.net/ujh3qny.css");

.pageWrapper {
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
}

.logo {
  max-width: 50px;
  pointer-events: none;
  margin-bottom: 20px;
}

h1 {
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  margin: 0;
}

h1.hero {
  text-align: left;
}

.section {
  /* width: 1600px; */
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.caseStudyHero {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.heroImage {
  width: 100%;
  height: auto;
}

.heroCopy {
  text-align: left;
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
}

.paraCopy {
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  font-weight: 300;
}

.soloCopy {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  h1 {
    font-size: 28px;
    font-weight: 400;
  }
  .heroCopy {
    text-align: center;
    font-size: 18px;
    line-height: 28px;
  }
  h1.hero {
    text-align: center;
  }
  .paraCopy {
    font-size: 18px;
    line-height: 28px;
  }
}

.fullWidth {
  width: 100%;
  max-width: 100%;
}

.caseStudyItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.constructionOverlay {
  position: absolute;
  font-size: calc(10px + 2vmin);
  font-family: europa;
  font-weight: 700;
  opacity: 0;
  transition: 0.2s ease;
  /* filter: invert(1); */
  /* background-color: rgba(0, 0, 0, 0.6); */
  /* padding: 10px 20px; */
  /* border-radius: 10px; */
}

.caseStudyItem:hover .constructionOverlay {
  opacity: 1;
}

.footer {
  font-size: calc(10px + 2vmin);
  font-family: europa;
  font-weight: 700;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
}

.socialIcons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.socialIcon {
  cursor: pointer;
  width: 40px;
  height: 40px;
  opacity: 0.4;
  transition: 0.5s ease;
  /* background: black; */
}

.socialIcon:hover {
  opacity: 1;
}

.socialIcon.instagram {
  background-image: url("./SocialIcons/Instagram.png");
  background-size: cover;
}

.socialIcon.twitter {
  background-image: url("./SocialIcons/Twitter.png");
  background-size: cover;
}

.socialIcon.vimeo {
  background-image: url("./SocialIcons/Vimeo.png");
  background-size: cover;
}
